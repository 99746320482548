:root {
  --bg: #2f446d;
  --bg-accent: #d6a319;
  --bg-nav: #1f293e;
  --text-color: #dadce1;
  --dark-text-color: #1b1b1c;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

/* Remove boostrap Blue link hover */
a:hover,
.nav-link:focus,
.nav-link:hover {
  color: var(--text-color);
}

.app-container {
  height: 100vh;
}

.nav-main {
  background-color: var(--bg-nav);
  height: 9vh;
}

.content-display-container {
  background-color: var(--bg);
  height: 81vh;
}
.content-display-container-alert {
  background-color: var(--bg);
  height: 75vh;
}

.nav-link .active {
  color: var(--dark-text-color);
}

@media screen and (max-width: 768px) {
  .timeframe-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .centerContainer {
    grid-column: span 12;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 110%;
  }
  .timeframe-button-container {
    display: flex;
    height: auto;
    align-items: center;
  }
  .centerContainer {
    grid-column: 5 / 8;
  }
  .item-bg:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 120%;
  }
}
@media screen and (min-width: 1200px) {
  html {
    font-size: 120%;
  }
}

.primary-container {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  top: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.nav-link {
  color: var(--text-color);
}

.capture-button {
  margin-top: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.capture-button:hover {
  .camera-icon {
    filter: invert(1);
  }
}

.capture-button-mobile {
  display: flex;
  flex-direction: column;
  top: 25%;
  position: absolute;
  left: 2%;
  align-items: center;
  justify-content: center;
}

.camera-icon .login-page-text {
  text-align: center;
}
.login-page-credits a {
  text-decoration: underline;
}

.clickable:hover {
  cursor: pointer;
}

body {
  height: 100vh;
  width: 100vw;
  background-color: var(--bg);
}

.loading-spinner {
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.loading-spinner span {
  transform: scale(3);
}

.text-main {
  color: white;
  font-size: 1.1rem;
  overflow: hidden;
}

.text-main > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-sub {
  color: rgb(185, 185, 185);
}

.desktop-data-container {
  grid-template-columns: 1fr 10vw 1fr;
  gap: 1rem;
  justify-items: center;
  height: 60vh;
  margin-top: 1rem;
}

.desktop-sub-data-container {
  display: flex;
  flex-direction: column;
  width: 40vw;
  justify-content: center;
  flex-shrink: 1;
}

.desktop-sub-data-container .data-container {
  height: 9vh;
}

.mobile-menu-horizontal-line {
  border-bottom: 2px solid rgb(148, 148, 148);
  width: 100%;
}

.timeframe-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-body a {
  color: #242526;
  text-decoration: underline;
}

.rightmost-nav {
  display: flex;
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(80%, -50%);
  align-items: center;
  margin-right: 2rem;
}

/* Overriding Navbar Collapse settings */
#responsive-navbar-nav {
  flex-grow: 0;
  background-color: var(--bg-nav);
  border-radius: 2%;
}

.timeframe-button-group > .btn {
  display: flex;
  align-content: center;
}

ul.nav {
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.tab-content {
  display: flex;
  justify-content: center;
}

h1,
h2,
h3,
h4 {
  color: white;
}

.modal-body h2 {
  color: black;
}

.user-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
}

.spotifyDataContainer {
  display: grid;
  grid-template-columns: repeat(11, minmax(0, 1fr));
  grid-template-rows: 1fr;
}

.centerContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  object-fit: cover;
}

.screenshotButtonContainer {
}

.desktopArtistContainer {
  grid-column: 1 / 5;
  margin-left: 1rem;
}

.desktopTrackContainer {
  grid-column: 8 / 12;
  margin-right: 1rem;
}

.desktopArtistContainer,
.desktopTrackContainer {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-logo {
  max-width: 40vw;
}

.user-name-container {
  display: flex;
  justify-content: center;
}

.user-image-container {
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1;
  max-height: 20vh;
  margin-top: 2rem;
}

.user-image {
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  border: 1px solid;
  border-radius: 50%;
  border-width: 5px;
  border-color: var(--bg-nav);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.item-bg {
  background-color: #465f91;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.data-container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  height: 10vh;
}

.tab-pane .data-container {
  height: 10vh;
  width: 90vw;
}
.spotify-content-link {
  height: 100%;
  width: 100%;
}

.spotify-content-link-icon-desktop {
  align-self: flex-end;
  position: absolute;
  transform: translate(-100%, 100%);
  opacity: 0%;
  text-decoration: none;
  transition: 0.4s;
}

.spotify-content-link-icon-mobile {
  align-self: flex-end;
  position: absolute;
  transform: translate(-100%, 100%);
  opacity: 50%;
  text-decoration: none;
}

.item-bg:hover > .spotify-content-link-icon-desktop {
  opacity: 80%;
}

.result-image {
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

/* Dropdown */
#mobile-menu {
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 5px;
}
#mobile-menu:hover {
  cursor: pointer;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 70px;
  display: flex;
  align-items: center;
  transition: background var(--speed);
  padding: 1rem;
  color: none;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.icon-right {
  margin-left: auto;
}

.icon-button {
  position: relative;
  right: 5px;
  top: 0px;
}

.menu-item-text {
  position: absolute;
  left: 3em;
}
